export default {
  eduCourse: {
    type: {
      DIGITAL_EDU_CONTENT: 0,
      SECTION: 1,
      THEME: 2,
      EDU_MATERIAL: 3
    },
    types: ['ЦОК', 'Раздел', 'Тема', 'Учебный материал'],
    tags: {
      groupLabels: {
        A1: 'Профиль класса',
        A2: 'Наименование уровня образования',
        A3: 'Наименование образовательного стандарта',
        A4: 'Наименование предметной области',
        A5: 'Наименование учебного предмета',
        A8: 'Наименование раздела учебного предмета',
        A6: 'Уровень преподавания',
        A7: 'Вид материала',
        A9: 'Тип материала',
        A10: 'Инклюзия'
      }
    },
    statuses: {
      submitted: { label: 'Подана', color: 'primary' },
      at_work: { label: 'В работе', color: 'amber' },
      solved: { label: 'Решено', color: 'green' },
      in_archive: { label: 'В архиве', color: 'grey' }
    }
  }
}