import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
// import ApiHelper from '@/helpers/ApiHelper'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Main',
		component: () => import('@/views/Main.vue'),
	},
	{
		path: '/transfer/export',
		name: 'TransferExport',
		component: () => import('@/views/admin/TransferExport.vue'),
	},
	{
		path: '/transfer/import',
		name: 'TransferImport',
		component: () => import('@/views/admin/TransferImport.vue'),
	},
	{
		path: '/build',
		name: 'Build',
		component: () => import('@/views/admin/Build.vue'),
	},
	{
		path: '/metrics',
		name: 'Metrics',
		component: () => import('@/views/admin/Metrics.vue'),
	},
	{
		path: '/support',
		name: 'Support',
		component: () => import('@/views/admin/Support.vue'),
	},
	{
		path: '/commands',
		name: 'Commands',
		component: () => import('@/views/admin/Commands.vue'),
	},
	{
		path: '/sync',
		name: 'Sync',
		component: () => import('@/views/admin/Sync.vue'),
	},
	{
		path: '/stats',
		name: 'Stats',
		component: () => import('@/views/admin/Stats.vue'),
	},
	{
		path: '/reports',
		name: 'Reports',
		component: () => import('@/views/admin/Reports.vue'),
	},
	{
		path: '/redirect',
		name: 'SsoRedirect',
		component: () => import('@/views/SsoRedirect.vue'),
	},
	{
		path: '/sso',
		name: 'SsoConnection',
		component: () => import('@/views/SsoConnection.vue'),
	},
	{
		path: '/login',
		redirect: '/'
	},
	{
		path: '/r/:shortId+',
		name: 'ResourceView',
		component: () => import('@/views/ResourceView.vue'),
	},
	{
		path: '/dec/:externalId+',
		name: 'DigitalContentView',
		component: () => import('@/views/DigitalContentView.vue'),
	},
	// Копия страницы DigitalContentView,
	// но без необходимости авторизовываться через партнеров
	{
		path: '/digital-content/:externalId+',
		name: 'DigitalContentViewUnprotected',
		component: () => import('@/views/DigitalContentViewUnprotected.vue'),
	},
	{
		path: '/start',
		name: 'DigitalContentStartView',
		component: () => import('@/views/DigitalContentStartView.vue'),
	},
	{
		path: '/feedback',
		name: 'Feedback',
		component: () => import('@/views/Feedback.vue')
	},
	{
		path: '*',
		name: 'NotFound',
		component: () => import('@/views/404.vue')
	}
]

const router = new VueRouter({
	mode: 'history',
	routes
})

// Events 
router.beforeEach(async (to, from, next) => {
	// Save user router stats
	// ApiHelper.post(`/api/metrics/create`, {
	// 	url: to.fullPath
	// });
	console.warn('Call to route "/api/metrics/create" have been disabled temporary by you')
	// Unauthorized redirect to login page
	const publicViews = [
		'Main', 'ResourceView', 'DigitalContentView', 'DigitalContentViewUnprotected',
		'DigitalContentStartView', 'SsoConnection', 'SsoRedirect', 'Feedback'
];
	if ((!store.getters['user/isAuthorized'] || store.getters['user/isEducontUser']) && !_.includes(publicViews, to.name)) {
		next('/');
		return;
	}
	
	next();
});

export default router
