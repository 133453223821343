import ApiHelper from '@/helpers/ApiHelper'
import FileUploadHelper from '@/helpers/FileUploadHelper'

export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {
        async uploadDocument(context, data) {
            return FileUploadHelper.upload({
				url: '/api/support/upload-document',
				file: data.file,
                payload: _.omit(data, ['file'])
			})
        },
        async createTicket(context, data = null) {
            return ApiHelper.post(`/api/support/create-ticket`, data);
        },
        async ticketList(context, data = null) {
            return ApiHelper.post(`/api/support/list`, data);
        },
        async ticketDetails (context, data = null) {
            return ApiHelper.post(`/api/support/ticket-details`, data);
        },
        async updateTicket (context, data = null) {
            return ApiHelper.post(`/api/support/update-ticket`, data);
        }
    },
    getters: {}
}