export default {
    // Insert element into position
    insertIn(arr, i, item) {
        const a = [...arr];
        a.splice(i, 0, item);
        return a;
    },
    insertInStart(arr, item) {
        return this.insertIn(arr, 0, item);
    },
    insertInEnd(arr, item) {
        return this.insertIn(arr, arr.length, item);
    },
    // Move element to position
    moveTo(arr, from, to) {
        const a = [...arr];
        a.splice(to, 0, a.splice(from, 1)[0]);
        return a;
    },
    // Move element on 1 position to start
    moveUp(arr, i) {
        return this.moveTo(arr, i, Math.max(0, i - 1));
    },
    // Move element on 1 position to end
    moveDown(arr, i) {
        return this.moveTo(arr, i, Math.min(arr.length - 1, i + 1));
    },
    // Move element to start position
    moveToStart(arr, i) {
        return this.moveTo(arr, i, 0);
    },
    // Move element to end position
    moveToEnd(arr, i) {
        return this.moveTo(arr, i, arr.length - 1);
    },
    removeAt(arr, i) {
        const a = [...arr];
        a.splice(i, 1);
        return a;
    },
    // Convert flat array to tree; uses id, parent_id
    toTree(arr, pk = 'id', parentKey = 'parent_id', childrenKey = 'children', noChildrenKey = 'noChildren') {
        const result = [];
        const hashTable = Object.create(null);
        arr.forEach(aData => hashTable[aData[pk]] = {...aData, [childrenKey]: (aData[noChildrenKey] === true ? null : [])});
        arr.forEach(aData => {
            if(aData[parentKey]) 
            {
                if(hashTable[aData[parentKey]])
                {
                    hashTable[aData[parentKey]][childrenKey].push(hashTable[aData[pk]])
                }
            }
            else 
                result.push(hashTable[aData[pk]])
        });
        return result;
    },
    /**
     * Метод дает убедиться, что переданное значение - это массив.
     * Если был передан массив, то его и возвращаем обратно.
     * Иначе обарачиваем значение в массив и возвращаем.
     * @param {any} value Любое значение
     * @returns {Array}
     */
    promiseArray (value) {
        return _.isArray(value) ? value : [value]
    },
    /**
     * Сортирует массив объектов по значениям свойств.
     * Есть возможность передать первым аргументом объект с перечислением полей и порядка сортировок,
     * для сортировки по нескольким полям сразу. Пример: `{ field1: 1, field2: -1}`
     * @param {String|Object} field Поле объекта по которому нужно сортировать
     * @param {Number} order Порядок сортировки. Возможные значения `1` и `-1`
     * @returns {Array}
     */
    compare(field, order) {
        var len = arguments.length;
        if(len === 0) {
            return (a, b) => (a < b && -1) || (a > b && 1) || 0;
        }
        if(len === 1) {
            switch(typeof field) {
                case 'number':
                    return field < 0 ?
                        ((a, b) => (a < b && 1) || (a > b && -1) || 0) :
                        ((a, b) => (a < b && -1) || (a > b && 1) || 0);
                case 'string':
                    return (a, b) => (a[field] < b[field] && -1) || (a[field] > b[field] && 1) || 0;
            }
        }
        if(len === 2 && typeof order === 'number') {
            return order < 0 ?
                ((a, b) => (a[field] < b[field] && 1) || (a[field] > b[field] && -1) || 0) :
                ((a, b) => (a[field] < b[field] && -1) || (a[field] > b[field] && 1) || 0);
        }
        var fields, orders;
        if(typeof field === 'object') {
            fields = Object.getOwnPropertyNames(field);
            orders = fields.map(key => field[key]);
            len = fields.length;
        } else {
            fields = new Array(len);
            orders = new Array(len);
            for(let i = len; i--;) {
                fields[i] = arguments[i];
                orders[i] = 1;
            }
        }
        return (a, b) => {
            for(let i = 0; i < len; i++) {
                if(a[fields[i]] < b[fields[i]]) return orders[i];
                if(a[fields[i]] > b[fields[i]]) return -orders[i];
            }
            return 0;
        };
    }
}