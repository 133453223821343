import ApiHelper from '@/helpers/ApiHelper'
import localForage from "localforage";
localForage.config({
    driver: localForage.INDEXEDDB,
    name: `${process.env.VUE_APP_CODENAME}_app_storage`
});

export default class {
    constructor() {
        this.state = {
            access_token: null,
            profile: null,
            educont_info: null // Основные авторизационные данные о пользователе, полученные через sso процедуру
        };
        this.mutations = {
            LOGIN(state, data) {
                state.access_token = data.access_token;
                state.profile = data.profile;
                state.educont_info = data.educont_info;

                localForage.setItem('access_token', state.access_token); 
                localForage.setItem('profile', state.profile);    
                localForage.setItem('educont_info', state.educont_info);    
            },
            LOGOUT(state) {
                state.access_token = null;
                state.profile = null;
                state.educont_info = null;
            }
        };
        this.actions = {
            async setSession({commit}, data) {
                const payload = {
                    access_token: data?.access_token || null,
                    profile: data?.profile || null,
                    educont_info: data?.educont_info || null
                };
                commit('LOGIN', payload);
            },
            async restoreSession({ dispatch }) {
                const access_token = await localForage.getItem('access_token');
                const profile = await localForage.getItem('profile');
                const educont_info = await localForage.getItem('educont_info');

                await dispatch('setSession', {
                    access_token,
                    profile,
                    educont_info
                });

                return true;
            },
            async login(context, data) {
                return ApiHelper.post('/api/login', data);
            },
            async logout({ commit }) {
                await localForage.removeItem('profile');
                await localForage.removeItem('access_token');
                await localForage.removeItem('educont_info');

                commit('LOGOUT');

                return true;
            }
        };
        this.getters = {
            isAuthorized: (state) => {
                return !_.isNil(_.get(state, 'profile.id', null));
            },
            isAdmin: (state) => {
                return _.get(state, 'profile.role', null) === 'admin';
            },
            isViewer: (state) => {
                return _.get(state, 'profile.role', null) === 'viewer';
            },
            isSchool: (state) => {
                return _.get(state, 'profile.role', null) === 'school';
            },
            isSupport: (state) => {
                return _.get(state, 'profile.role', null) === 'support';
            },
            isEducontUser: (state) => {
                return typeof _.get(state, 'educont_info.ID', null) === 'string';
            }
        }
    }
}