import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import ru from 'vuetify/lib/locale/ru';
import DatetimePicker from 'vuetify-datetime-picker'

Vue.use(Vuetify);
Vue.use(DatetimePicker)

export default new Vuetify({
    lang: {
      locales: { ru },
      current: 'ru',
    },
    theme: {
      themes: {
        light: {
          error: '#ED457E',
          primary: '#2D9CDB'
        },
      },
    },
});
