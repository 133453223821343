import ApiHelper from '@/helpers/ApiHelper'

export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {
        list(context, data = null) {
            return ApiHelper.get(`/api/stats`, data);
        },
        transactionList(context, data = null) {
            return ApiHelper.get(`/api/stats/transaction`, data);
        },
        licenseList(context, data = null) {
            return ApiHelper.get(`/api/stats/license`, data);
        },
        licenseListTotal(context, data = null) {
            return ApiHelper.get(`/api/stats/license-total`, data);
        },
        resend(context, data = null) {
            return ApiHelper.post(`/api/stats/resend`, data);
        }
    },
    getters: {}
}