import ApiHelper from '@/helpers/ApiHelper'

export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {
        async addTagToDec (context, data = null) {
            return ApiHelper.get(`/api/developer/add-tag-to-dec`, data);
        },
        async copyDecTagGroups (context, data = null) {
            return ApiHelper.get(`/api/developer/copy-dec-tags-groups`, data);
        },
        async replaceSubstringFromField (context, data = null) {
            return ApiHelper.get(`/api/developer/replace-substring-from-dec-field`, data);
        }
    },
    getters: {}
}