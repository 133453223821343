<template>
  <v-app id="app-container" :class="[routeName, isAuthorized ? 'authorized' : 'unauthorized', isEducontUser ? 'educont-user' : '']">
    <layout>
        <router-view :key="$route.path" />
    </layout>
  </v-app>
</template>
<script>
import Layout from '@/components/template/Layout.vue'

export default {
    name: 'App',
    components: { Layout },
    computed: {
        routeName () {
            return 'page-' + this.$route.name?.toLowerCase()
        },
        isAuthorized () {
            return this.$store.getters['user/isAuthorized']
        },
        isEducontUser () {
            return this.$store.getters['user/isEducontUser']
        }
    }
};
</script>