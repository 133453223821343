import Vue from 'vue'
import Vuex from 'vuex'

import app from './modules/app'
import user from './modules/user'
import metrics from './modules/metrics'
import stats from './modules/stats'
import support from './modules/support'
import commands from './modules/commands'
import transfer from './modules/transfer'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    app,
    user,
    metrics,
    support,
    commands,
    stats,
    transfer
  }
})
