import ApiHelper from './ApiHelper'
export default {
    upload(options) {
        // Validate params
        if(_.size(_.get(options, 'url', '')) <= 0)
            throw new Error('Не указан URL для загрузки.');
        if(_.isNil(_.get(options, 'file')))
            throw new Error('Не указан выбранный файл.');

        return new Promise((resolve) => {
            // Uploaded file 
            const file = options.file;
            // Chunk size, default: 30Mb
            const chunkSize = _.get(options, 'chunkSize', 30 * 1024 * 1024);
            // Create reader
            const reader = new FileReader();
            // Loaded progress
            let loaded = 0;
            // Create chunk of file
            let chunk = file.slice(loaded, loaded + chunkSize);
            reader.readAsArrayBuffer(chunk);
            // Chunk load event
            reader.onload = async event => {
                // Create form data for send to backend
                const form = new FormData();
                form.append('start', loaded === 0 ? 1 : 0);
                form.append('complete', loaded + chunkSize > file.size ? 1 : 0);
                form.append('filename', file.name);
                form.append('chunk', new File([event.target.result], 'filechunk'));
                // Post data
                _.forIn(_.get(options, 'payload', {}), (v,k) => {
                    form.append(k, v);
                })
                // Send data
                const response = await ApiHelper.post(options.url, form, _.get(options, 'query', {}))
                // On server error
                if(!response.success)
                {
                    resolve(response);
                    return ;
                }
                // Update progress
                loaded += chunkSize;
                if(_.has(options, 'onProgress'))
                {
                    const progressValue = Math.floor((loaded / (file.size - (file.size % chunkSize))) * 100);
                    options.onProgress(progressValue)
                }
                // Create & handle next chunk or emit success if file succesfully loaded
                if(loaded <= file.size)
                {
                    chunk = file.slice(loaded, loaded + chunkSize);
                    reader.readAsArrayBuffer(chunk);
                }
                else
                    resolve(response);
            };
        });
    }
}