import ApiHelper from '@/helpers/ApiHelper'

export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {
        async list(context, data = null) {
            return ApiHelper.get(`/api/metrics`, data);
        },
        async totalUniqVisitorCount(context, data = null) {
            return ApiHelper.get(`/api/metrics/total-uniq-visitor-count`, data);
        }
    },
    getters: {}
}