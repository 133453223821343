import ApiHelper from '@/helpers/ApiHelper'

export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {
        async list() {
            return ApiHelper.get(`/api/transfer`);
        },
        async export(context, data) {
            return ApiHelper.post(`/api/transfer/export`, {options: data});
        },
        async exportCheck() {
            return ApiHelper.get(`/api/transfer/export-check`);
        },
        async import(context, data) {
            return ApiHelper.post(`/api/transfer/import`, {options: data});
        },
        async importCheck() {
            return ApiHelper.get(`/api/transfer/import-check`);
        }
    },
    getters: {}
}