import CAuth from '@/store/classes/auth';
const auth = new CAuth()

export default {
    namespaced: true,
    state: {
        ...auth.state
    },
    mutations: {
        ...auth.mutations
    },
    actions: {
        ...auth.actions
    },
    getters: {
        ...auth.getters,
        allowEdit: (state) => {
            return _.get(state, 'profile.role', null) === 'admin';
        },
        allowSupport: (state) => {
            return ['admin', 'support'].includes(_.get(state, 'profile.role', null));
        }
    }
}