import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Vue global settings
Vue.config.productionTip = false
// Constants
import constants from './constants'
Object.defineProperty(Vue.prototype, '$const', {
  value: constants
});
// Import & set global libs
import _ from 'lodash'
Object.defineProperty(Vue.prototype, '_', { value: _ });
Object.defineProperty(Vue.prototype, '$env', { value: process.env });

// Import global SCSS styles
import '@/assets/scss/main.scss'

import vuetify from './plugins/vuetify'
import '@babel/polyfill'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
// App init function
const init = async () => {
  // Restore state from local storage
  await store.dispatch('user/restoreSession');

  // Init app
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app');
}
// App start event
document.addEventListener("deviceready", init, false);
// All platform apps except mobile starts immediatelly
if (process.env.VUE_APP_PLATFORM_TYPE !== 'mobile')
  document.dispatchEvent(new Event('deviceready'));
